// import React, { useState } from "react";
// import {
//   BottomContainer,
//   NavbarContainer,
//   NavbarInnerContainer,
//   NavbarLink,
//   NavbarLinkContainer,
//   TopContainer,
//   Logo,
//   OpenLinksButton,
//   NavbarExtendedContainer,
//   NavbarLinkExtended,
// } from "./Navbar.Styles";
// import LogoImage from "../../images/150.png";

// export const Navbar = () => {
//   const [extendNavbar, setExtendNavbar] = useState(false);

//   const HandleExtendNavbar = () => {
//     setExtendNavbar((prevState) => !prevState);
//   };

//   return (
//     <NavbarContainer extendNavbar={extendNavbar}>
//       <NavbarInnerContainer>
//         <TopContainer>
//           <Logo src={LogoImage} />
//           <OpenLinksButton onClick={HandleExtendNavbar}>
//               {extendNavbar ? <> &#10005; </> : <> &#8801; </>}
//           </OpenLinksButton>
//         </TopContainer>
//         <BottomContainer>
//           <NavbarLinkContainer>
//             <NavbarLink to="/">Home</NavbarLink>
//             <NavbarLink to="/about">About</NavbarLink>
//             <NavbarLink to="/portfolio">Services</NavbarLink>
//             <NavbarLink to="/contact">Contact</NavbarLink>
//           </NavbarLinkContainer>
//         </BottomContainer>
//       </NavbarInnerContainer>
//       {extendNavbar && (
//         <NavbarExtendedContainer>
//           <NavbarLinkExtended to="/">Home</NavbarLinkExtended>
//           <NavbarLinkExtended to="/about">About</NavbarLinkExtended>
//           <NavbarLinkExtended to="/portfolio">Services</NavbarLinkExtended>
//           <NavbarLinkExtended to="/contact">Contact</NavbarLinkExtended>
//         </NavbarExtendedContainer>
//       )}
//     </NavbarContainer>
//   );
// };

import React from "react";
import {
  ChakraProvider,
  Box,
  Flex,
  IconButton,
  Image,
  Link,
  useDisclosure,
} from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import LogoImage from "../../images/150.png";

const NavbarContent = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box
      as="nav"
      w="100%"
      bg="#1a237e"
      boxShadow="0px 4px 8px rgba(0, 0, 0, 0.2)"
      transition="height 0.3s ease"
    >
      <Flex
        justify="space-between"
        align="center"
        p="3.6vh 20px"
        position="relative"
      >
        <Image
          src={LogoImage}
          height="120px"
          position="absolute"
          left="10px"
          top="50%"
          transform="translateY(-50%)"
          objectFit="cover"
          transition="filter 0.5s"
          _hover={{ cursor: "pointer", filter: "drop-shadow(0px 0px 5px #3949ab)" }}
        />
        <Flex
          display={{ base: "none", md: "flex" }}
          align="center"
          justify="flex-end"
          w="100%"
        >
          <Link
            as={RouterLink}
            to="/"
            color="white"
            fontSize="18px"
            fontFamily="Arial, sans-serif"
            textDecoration="none"
            m="0 15px"
            position="relative"
            transition="color 0.3s, background-color 0.3s"
            _hover={{
              color: "#fff",
              _after: {
                content: "''",
                position: "absolute",
                width: "100%",
                height: "2px",
                bottom: "-2px",
                left: "0",
                bg: "white",
                visibility: "visible",
                transform: "scaleX(1)",
                transition: "transform 0.5s ease-in-out",
              },
            }}
            _after={{
              content: "''",
              position: "absolute",
              width: "100%",
              height: "2px",
              bottom: "-2px",
              left: "0",
              bg: "white",
              visibility: "hidden",
              transform: "scaleX(0)",
              transformOrigin: "left",
              transition: "transform 0.5s ease-in-out",
            }}
          >
            Home
          </Link>
          <Link
            as={RouterLink}
            to="/about"
            color="white"
            fontSize="18px"
            fontFamily="Arial, sans-serif"
            textDecoration="none"
            m="0 15px"
            position="relative"
            transition="color 0.3s, background-color 0.3s"
            _hover={{
              color: "#fff",
              _after: {
                content: "''",
                position: "absolute",
                width: "100%",
                height: "2px",
                bottom: "-2px",
                left: "0",
                bg: "white",
                visibility: "visible",
                transform: "scaleX(1)",
                transition: "transform 0.5s ease-in-out",
              },
            }}
            _after={{
              content: "''",
              position: "absolute",
              width: "100%",
              height: "2px",
              bottom: "-2px",
              left: "0",
              bg: "white",
              visibility: "hidden",
              transform: "scaleX(0)",
              transformOrigin: "left",
              transition: "transform 0.5s ease-in-out",
            }}
          >
            About
          </Link>
          <Link
            as={RouterLink}
            to="/portfolio"
            color="white"
            fontSize="18px"
            fontFamily="Arial, sans-serif"
            textDecoration="none"
            m="0 15px"
            position="relative"
            transition="color 0.3s, background-color 0.3s"
            _hover={{
              color: "#fff",
              _after: {
                content: "''",
                position: "absolute",
                width: "100%",
                height: "2px",
                bottom: "-2px",
                left: "0",
                bg: "white",
                visibility: "visible",
                transform: "scaleX(1)",
                transition: "transform 0.5s ease-in-out",
              },
            }}
            _after={{
              content: "''",
              position: "absolute",
              width: "100%",
              height: "2px",
              bottom: "-2px",
              left: "0",
              bg: "white",
              visibility: "hidden",
              transform: "scaleX(0)",
              transformOrigin: "left",
              transition: "transform 0.5s ease-in-out",
            }}
          >
            Services
          </Link>
          <Link
            as={RouterLink}
            to="/contact"
            color="white"
            fontSize="18px"
            fontFamily="Arial, sans-serif"
            textDecoration="none"
            m="0 15px"
            position="relative"
            transition="color 0.3s, background-color 0.3s"
            _hover={{
              color: "#fff",
              _after: {
                content: "''",
                position: "absolute",
                width: "100%",
                height: "2px",
                bottom: "-2px",
                left: "0",
                bg: "white",
                visibility: "visible",
                transform: "scaleX(1)",
                transition: "transform 0.5s ease-in-out",
              },
            }}
            _after={{
              content: "''",
              position: "absolute",
              width: "100%",
              height: "2px",
              bottom: "-2px",
              left: "0",
              bg: "white",
              visibility: "hidden",
              transform: "scaleX(0)",
              transformOrigin: "left",
              transition: "transform 0.5s ease-in-out",
            }}
          >
            Contact
          </Link>
        </Flex>
        <IconButton
          aria-label="Open Menu"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          display={{ base: "flex", md: "none" }}
          onClick={onToggle}
          color="white"
          bg="none"
          fontSize="30px"
          position="absolute"
          right="20px"
          top="50%"
          transform="translateY(-50%)"
        />
      </Flex>
      {isOpen && (
        <Box
          bg="#1a237e"
          pt="10px"
          mt="0px" 
          borderTop={"1px solid #fff"}
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.2)"
          position="absolute"
          left="0"
          right="0"
          zIndex={"5"}
        >
          <Link
            as={RouterLink}
            to="/"
            color="white"
            fontSize="18px"
            fontFamily="Arial, sans-serif"
            textDecoration="none"
            display="block"
            p="10px 20px"
            transition="color 0.3s, background-color 0.3s"
            _hover={{
              color: "#fff",
              _after: {
                content: "''",
                position: "absolute",
                width: "100%",
                height: "2px",
                bottom: "-2px",
                left: "0",
                bg: "white",
                visibility: "visible",
                transform: "scaleX(1)",
                transition: "transform 0.5s ease-in-out",
              },
            }}
            _after={{
              content: "''",
              position: "absolute",
              width: "100%",
              height: "2px",
              bottom: "-2px",
              left: "0",
              bg: "white",
              visibility: "hidden",
              transform: "scaleX(0)",
              transformOrigin: "left",
              transition: "transform 0.5s ease-in-out",
            }}
          >
            Home
          </Link>
          <Link
            as={RouterLink}
            to="/about"
            color="white"
            fontSize="18px"
            fontFamily="Arial, sans-serif"
            textDecoration="none"
            display="block"
            p="10px 20px"
            transition="color 0.3s, background-color 0.3s"
            _hover={{
              color: "#fff",
              _after: {
                content: "''",
                position: "absolute",
                width: "100%",
                height: "2px",
                bottom: "-2px",
                left: "0",
                bg: "white",
                visibility: "visible",
                transform: "scaleX(1)",
                transition: "transform 0.5s ease-in-out",
              },
            }}
            _after={{
              content: "''",
              position: "absolute",
              width: "100%",
              height: "2px",
              bottom: "-2px",
              left: "0",
              bg: "white",
              visibility: "hidden",
              transform: "scaleX(0)",
              transformOrigin: "left",
              transition: "transform 0.5s ease-in-out",
            }}
          >
            About
          </Link>
          <Link
            as={RouterLink}
            to="/portfolio"
            color="white"
            fontSize="18px"
            fontFamily="Arial, sans-serif"
            textDecoration="none"
            display="block"
            p="10px 20px"
            transition="color 0.3s, background-color 0.3s"
            _hover={{
              color: "#fff",
              _after: {
                content: "''",
                position: "absolute",
                width: "100%",
                height: "2px",
                bottom: "-2px",
                left: "0",
                bg: "white",
                visibility: "visible",
                transform: "scaleX(1)",
                transition: "transform 0.5s ease-in-out",
              },
            }}
            _after={{
              content: "''",
              position: "absolute",
              width: "100%",
              height: "2px",
              bottom: "-2px",
              left: "0",
              bg: "white",
              visibility: "hidden",
              transform: "scaleX(0)",
              transformOrigin: "left",
              transition: "transform 0.5s ease-in-out",
            }}
          >
            Services
          </Link>
          <Link
            as={RouterLink}
            to="/contact"
            color="white"
            fontSize="18px"
            fontFamily="Arial, sans-serif"
            textDecoration="none"
            display="block"
            p="10px 20px"
            transition="color 0.3s, background-color 0.3s"
            _hover={{
              color: "#fff",
              _after: {
                content: "''",
                position: "absolute",
                width: "100%",
                height: "2px",
                bottom: "-2px",
                left: "0",
                bg: "white",
                visibility: "visible",
                transform: "scaleX(1)",
                transition: "transform 0.5s ease-in-out",
              },
            }}
            _after={{
              content: "''",
              position: "absolute",
              width: "100%",
              height: "2px",
              bottom: "-2px",
              left: "0",
              bg: "white",
              visibility: "hidden",
              transform: "scaleX(0)",
              transformOrigin: "left",
              transition: "transform 0.5s ease-in-out",
            }}
          >
            Contact
          </Link>
        </Box>
      )}
    </Box>
  );
};

export const Navbar = () => (
  <ChakraProvider>
    <NavbarContent />
  </ChakraProvider>
);
