import React, { useState, useEffect, useRef } from "react";
import { AchievementCard, AchievementCount, AchievementLogo, AchievementName } from "./IncrementingAchievement.Styles";

export const IncrementingAchievement = ({ initialValue, finalValue, imageSrc, alt, name }) => {
  const [currentValue, setCurrentValue] = useState(initialValue);
  const observerRef = useRef(null);

  useEffect(() => {
    const duration = 3000;
    const interval = 50;
    const steps = duration / interval;

    const incrementStep = (final, initial, step) => {
      const diff = final - initial;
      return initial + (diff / steps) * step;
    };

    let step = 0;
    let intervalId = null;

    const startCounting = () => {
      setCurrentValue(initialValue);
      step = 0;
      intervalId = setInterval(() => {
        if (step <= steps) {
          setCurrentValue(incrementStep(finalValue, initialValue, step));
          step += 1;
        } else {
          clearInterval(intervalId);
        }
      }, interval);
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          startCounting();
        } else {
          clearInterval(intervalId);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
    });

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
      clearInterval(intervalId);
    };
  }, [initialValue, finalValue]);

  return (
    <AchievementCard ref={observerRef}>
      <AchievementLogo src={imageSrc} alt={alt} />
      <AchievementCount>{Math.round(currentValue)}{alt === "Customer Rating" ? "%" : "+"}</AchievementCount>
      <AchievementName>{name}</AchievementName>
    </AchievementCard>
  );
};
