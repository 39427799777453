import React from 'react';
import './Review.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const reviews = [
  {
    image: 'https://source.unsplash.com/random/100x100/?face',
    name: 'John Doe',
    review: 'This is an amazing service! Highly recommend to everyone.'
  },
  {
    image: 'https://source.unsplash.com/random/100x100/?portrait',
    name: 'Jane Smith',
    review: 'Great experience, very helpful and professional.'
  },
  {
    image: 'https://source.unsplash.com/random/100x100/?person',
    name: 'Alice Johnson',
    review: 'Exceptional quality and service. Will definitely use again.'
  },
  {
    image: 'https://source.unsplash.com/random/100x100/?human',
    name: 'Robert Brown',
    review: 'Very satisfied with the results. Professional and timely.'
  },
  {
    image: 'https://source.unsplash.com/random/100x100/?avatar',
    name: 'Emma Wilson',
    review: 'The support team is amazing. Great service overall.'
  }
];

const Review = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 1200, min: 992 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 992, min: 576 },
      items: 1.5
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1.5,
      partialVisibilityGutter: 30 // Adjusted for better visibility on mobile
    }
  };

  return (
    <div className="review-section">
      <h2 className="review-title">What Our Clients Say</h2>
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        showDots={true}
        containerClass="carousel-container"
        itemClass="carousel-item"
        dotListClass="custom-dot-list-style"
      >
        {reviews.map((review, index) => (
          <div key={index} className="review-card">
            <img src={review.image} alt='' className="review-image" />
            <h3 className="review-name">{review.name}</h3>
            <p className="review-text">"{review.review}"</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Review;
