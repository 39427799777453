import React from "react";
import mylogo from "../../images/logonew.png";
import {
  ContactItem,
  ContactsContainer,
  ContactsOuterContainer,
  ContactsTitle,
  Description,
  DisclaimerContainer,
  DisclaimerOuterContainer,
  Logo,
  ServiceItem,
  ServiceTitle,
  ServicesContainer,
  ServicesOuterContainer,
  Title,
} from "./DesclaimerComponent.Styles";

export const DesclaimerComponent = () => {
  return (
    <DisclaimerContainer>
      <DisclaimerOuterContainer>
        <Title>Disclaimer:</Title>
        <Description>
          The Assignment Educator is an academic help website that serves online
          clientele within all the local and international laws. We hold no
          responsibility for any losses incurred on the client while using this
          website.
        </Description>
        <Logo src={mylogo} alt="Company Logo" />
      </DisclaimerOuterContainer> 

      <ServicesOuterContainer>
        <ServicesContainer>
          <ServiceTitle>Services</ServiceTitle>
          <ServiceItem>Service 1</ServiceItem>
          <ServiceItem>Service 2</ServiceItem>
          <ServiceItem>Service 3</ServiceItem>
          <ServiceItem>Service 4</ServiceItem>
          <ServiceItem>Service 5</ServiceItem>
          <ServiceItem>Service 6</ServiceItem>
        </ServicesContainer>
      </ServicesOuterContainer>

      <ContactsOuterContainer>
        <ContactsContainer>
          <ContactsTitle>Contacts</ContactsTitle>
          <ContactItem>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-envelope"
              viewBox="0 0 16 16"
            >
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm14 0v.217L8 8.083 2 4.217V4h12zM2 5.383l6 3.766 6-3.766V12H2V5.383z"/>
            </svg>
            example@email.com
          </ContactItem>
          <ContactItem>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-phone"
              viewBox="0 0 16 16"
            >
              <path d="M3 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3zm8.5 9h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1zm0-3h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1zm0-3h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1z"/>
            </svg>
            +1 (123) 456-7890
          </ContactItem>
        </ContactsContainer>
      </ContactsOuterContainer>
    </DisclaimerContainer>
  );
};
