import styled from "styled-components";

export const FooterContainer = styled.footer`
  margin: 0;
  text-align: center;
  padding: 12px 0;
  color: white;
  background-color: #1a237e; 
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
`;
