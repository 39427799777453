import React from 'react';
import { FooterContainer } from './Footer.Styles';

export const Footer = () => {
  return (
    <FooterContainer>
        © 2023 Theassignmenteducator.com All Rights Reserved.
    </FooterContainer>
  );
};
