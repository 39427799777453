import styled from "styled-components";

export const DisclaimerContainer = styled.div`
  display: flex;
  padding: 20px 50px;
  background-color: #f5f5f5;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 700px) {
    flex-direction: column;
    padding: 20px;
  }
`;

export const DisclaimerOuterContainer = styled.div`
  flex: 60%;
  padding: 0 20px;
`;

export const Title = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #1a237e; /* Deep blue from the logo */
`;

export const Description = styled.p`
  margin-bottom: 20px;
  color: #333;
  line-height: 1.5;
`;

export const Logo = styled.img`
  max-width: 150px; /* Adjust the size as necessary */
  height: auto;
`;

export const ServicesOuterContainer = styled.div`
  flex: 20%;
  padding: 0 20px;

  @media (max-width: 700px) {
    padding: 20px 0;
  }
`;

export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ServiceTitle = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 15px;
  color: #1a237e; /* Deep blue from the logo */
`;

export const ServiceItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #555;
  transition: color 0.3s, padding-left 0.3s;

  &::before {
    content: "➤";
    margin-right: 10px;
    color: #1a237e;
  }

  &:hover {
    padding-left: 5px;
    cursor: pointer;
    color: #1a237e; /* Deep blue */
  }
`;

export const ContactsOuterContainer = styled.div`
  flex: 20%;
  padding: 0 20px;

  @media (max-width: 700px) {
    padding: 20px 0;
  }
`;

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactsTitle = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 15px;
  color: #1a237e; /* Deep blue from the logo */
`;

export const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #555;

  & > svg {
    margin-right: 10px;
    fill: #1a237e; 
  }
`;
